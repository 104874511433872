import ProgressBar from "~/spocketUI/components/ProgressBar";
import {
  useDropshippingSettings,
  useGetCategories,
  useUpdateDropshippingSettings,
} from "~/api/dropshipping/queries";
import FlexContainer from "~/spocketUI/components/FlexContainer";
import * as S from "./styles";
import { useCallback, useEffect, useState } from "react";
import Button from "~/spocketUI/components/Button";
import { useTranslation } from "react-i18next";
import { updateCategoriesByUser } from "~/api/dropshipping/requests";
import { useAccount } from "~/hooks/useAccount";
import handleErrors from "~/spocketUI/helpers/handleErrors";
import { useUpload } from "~/hooks/useUpload";
import { FormContext, useForm } from "~/spocketUI/hooks/useForm";
import { brandingFormConfig } from "./form";
import { useQueryClient } from "@tanstack/react-query";
import {
  DROPSHIPPING_SETTINGS,
  IDropshippingSettings,
} from "~/api/dropshipping/types";
import { IFile } from "~/spocketUI/types/upload";
import { triggerShowPlansModal } from "~/spocketUI/helpers/customEvents";
import { Form } from "~/spocketUI/components/Form";

type Props = {
  backStep: () => void;
};

export const SelectCategory = () => {
  const { t } = useTranslation();
  const { account, getAccount } = useAccount();
  const { data: categories } = useGetCategories();
  const [selectedCategories, setSelectedCategories] = useState<any>(
    account?.categories?.map((category) => category.id) || []
  );
  const [showPersonalizeBrand, setShowPersonalizeBrand] = useState(false);

  const newCategories = categories
    ? [
        { id: null, name: t("onboarding.tranding") },
        ...categories,
        { id: "all", name: t("onboarding.all") },
      ]
    : [];

  // Function to check if all categories are selected
  const areAllCategoriesSelected = () => {
    return (
      selectedCategories?.length === categories?.length &&
      selectedCategories?.every((catId: number) =>
        categories?.some((category) => category.id === catId)
      )
    );
  };

  // Function to handle 'all' selection
  const handleAllSelection = () => {
    if (areAllCategoriesSelected()) {
      setSelectedCategories([]);
    } else {
      setSelectedCategories(categories?.map((category) => category.id));
    }
  };

  // Function to handle individual category selection
  const handleCategorySelection = (id: string | number) => {
    setSelectedCategories((prev: any[]) => {
      if (prev.includes(id)) {
        return prev.filter((catId) => catId !== id);
      } else {
        // If 'null' is already selected, remove it before adding the new category
        const newCategories = prev.includes(null) ? [] : [...prev];
        return [...newCategories, id];
      }
    });
  };

  const handleCategorySelect = (id: string | number | null) => {
    if (id === null) {
      setSelectedCategories([null]);
    } else if (id === "all") {
      handleAllSelection();
    } else {
      handleCategorySelection(id);
    }
  };

  const handleContinue = async () => {
    if (selectedCategories) {
      const { errors } = await handleErrors(
        () =>
          updateCategoriesByUser({
            categories: selectedCategories.includes(null)
              ? null
              : selectedCategories,
          }),
        //@ts-ignore
        {}
      );
      if (!errors) {
        setShowPersonalizeBrand(true);
        getAccount();
      }
    }
  };

  if (showPersonalizeBrand)
    return <PersonalizeBrand backStep={() => setShowPersonalizeBrand(false)} />;

  return (
    <FlexContainer flexDirection="column" gap="20px">
      <FlexContainer
        flexDirection="column"
        gap="0"
        width="100%"
        alignItems="flex-start">
        <S.SelectCategoryTitle>
          {t("onboarding.category_title")}
        </S.SelectCategoryTitle>
        <ProgressBar progress={50} color="primary" />
      </FlexContainer>
      <FlexContainer gap="16px" flexWrap="wrap">
        {newCategories?.map((category) => (
          <S.CardCategory
            key={category.id}
            className={
              selectedCategories?.includes(category.id) ? "active" : ""
            }
            onClick={() => handleCategorySelect(category.id)}>
            {category.name}
          </S.CardCategory>
        ))}
      </FlexContainer>
      <FlexContainer justifyContent="space-between" width="100%">
        <Button
          bgColor="transparent"
          color="textSecondary"
          padding="16px 24px"
          onClick={() => setShowPersonalizeBrand(true)}>
          {t("onboarding.skip")}
        </Button>
        <Button
          bgColor="primary"
          color="white"
          padding="16px 24px"
          onClick={handleContinue}>
          {t("onboarding.continue")}
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export const PersonalizeBrand = ({ backStep }: Props) => {
  const { t } = useTranslation();
  const { data } = useDropshippingSettings();
  const { upload } = useUpload();
  const form = useForm(brandingFormConfig);
  const { mutateAsync: updateSettings } = useUpdateDropshippingSettings();
  const client = useQueryClient();

  useEffect(() => {
    if (data) {
      const { brand_name, brand_logo } = data;

      form.loadValues({
        brand_name,
        brand_logo,
      });
    }
  }, [data]);

  const handleBrandingSave = useCallback(async () => {
    const payload: IDropshippingSettings = {
      ...form.getValues(),
    };
    payload.brand_logo = ((payload.brand_logo as IFile) || {}).id as number;

    const { errors } = await handleErrors(
      () => updateSettings(payload),
      //@ts-ignore
      {}
    );
    if (!errors) {
      client.invalidateQueries(DROPSHIPPING_SETTINGS);
      triggerShowPlansModal();
    }
  }, [form]);

  return (
    <FlexContainer flexDirection="column" gap="20px">
      <FlexContainer
        flexDirection="column"
        gap="0"
        width="100%"
        alignItems="flex-start">
        <S.SelectCategoryTitle>
          {t("onboarding.personalize")}
        </S.SelectCategoryTitle>
        <ProgressBar progress={100} color="primary" />
      </FlexContainer>
      <FlexContainer gap="16px" width="100%" flexWrap="wrap">
        <FormContext.Provider value={{ uploadFile: upload }}>
          <Form {...form} />
        </FormContext.Provider>
      </FlexContainer>
      <FlexContainer justifyContent="space-between" width="100%">
        <Button
          bgColor="transparent"
          color="textSecondary"
          padding="16px 24px"
          onClick={() => triggerShowPlansModal()}>
          {t("onboarding.skip")}
        </Button>
        <FlexContainer>
          <Button
            bgColor="transparent"
            color="text"
            padding="16px 24px"
            onClick={() => backStep()}>
            {t("onboarding.back")}
          </Button>
          <Button
            bgColor="primary"
            color="white"
            padding="16px 24px"
            onClick={handleBrandingSave}>
            {t("onboarding.continue")}
          </Button>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
