import { Order, OrderType } from "~/types/dropshipping";

export const PRODUCTS = ["PRODUCTS"];
export const IMPORT_LIST = ["IMPORT_LIST"];
export const ORDERS = ["ORDERS"];
export const DROPSHIPPING_SETTINGS = ["DROPSHIPPING_SETTINGS"];

export interface ImportListParams {
  page?: number;
  is_live?: boolean;
}

export interface IPushToStoreParams {
  product_id: string;
  is_live?: boolean;
  description?: string;
  background_color?: string;
}

export interface IOrdersParams {
  page?: number;
  order_type?: OrderType;
}

export interface IOrdersList {
  data: Order[];
  page: number;
  total_pages: number;
}

export interface IAddressParams {
  first_name: string;
  last_name: string;
  line_1: string;
  line_2?: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  phone?: string;
  order_id?: number;
}

export interface ICreateSampleOrderParams {
  quantity?: number;
  additive?: boolean;
  variant_id: number;
}

export interface IUpdateRetailPriceParams {
  retail_price_cents: number;
  variant_id: number;
}

export interface IDropshippingSettings {
  shop?: number;
  brand_name?: string;
  brand_logo?: number | string;
  invoice_store_name?: string;
  invoice_contact_email?: string;
  invoice_website?: string;
  invoice_logo?: number | string;
  invoice_body?: string;
  font_family?: string;
  products_background_color?: string;
  distributor_city?: string;
  distributor_zip?: string;
}

export interface IUserCategories {
  categories: string[];
}
