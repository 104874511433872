import { IPaths } from "~/types/routing";

export const paths: IPaths = {
  home: "/",
  orders: "/orders",
  sampleOrders: "/sample-orders",
  customization: "/customization",
  importList: "/import-list",
  liveProducts: "/live-products",
  branding: "/branding"
};
